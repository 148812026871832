import { render, staticRenderFns } from "./DepartmentsDropdown.vue?vue&type=template&id=7a541b03&scoped=true&"
import script from "./DepartmentsDropdown.vue?vue&type=script&lang=ts&"
export * from "./DepartmentsDropdown.vue?vue&type=script&lang=ts&"
import style0 from "./DepartmentsDropdown.vue?vue&type=style&index=0&id=7a541b03&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a541b03",
  null
  
)

export default component.exports